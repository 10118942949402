import React, { useState, useEffect } from "react"
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import MaskedInput from "react-text-mask";

import {
      Formik,
      Form,
      Field,
      ErrorMessage,
      useFormikContext
} from 'formik'
import NumberFormat from "react-number-format";



const CuotasForm = ({ isMobile }) => {


      const showCrediton = true;



      //Form
      const campoError = 'El campo es requerido'
      var noValidate = false;
      var errors = {};

      //Setters
      const [loading, setLoading] = React.useState(false);
      const [showToast, setToast] = React.useState(false);
      const [payment, setPayment] = React.useState();
      const [isCrediton, setIsCrediton] = React.useState(false);
      const [isCreditonPlus, setIsCreditonPlus] = React.useState(false);
      const [t, setT] = React.useState(false);
      const [hasMounted, setHasMounted] = React.useState(false);



      const fetchApi = (values) => {


            if ((values && ((!showCrediton && values.total >= 50000 || showCrediton && values.total < 50000) && values.total <= 150000) || !values)) {
                  //Fetch to API
                  (async () => {

                        setLoading(true);


                        const rawResponse = await fetch(`https://becredweb.volmet.com.uy/api/v1/loan_simulations/get_monthly?payment_freq=${values && values.payment_freq ? values.payment_freq : 5}&total=${values && values.total ? values.total : showCrediton ? 10000 : 50000 }`, {
                              method: "GET",
                              headers: {
                                    'Accept': "application/json",
                                    'Content-Type': "application/json",
                                    'Authorization': 'Token token=fc785cfb17ba4c45b6b1'
                              }, withCredentials: true,
                        })


                        //Get API response
                        const response = await rawResponse.json();

                        //response.status == 200
                        if (response.status == "OK") {

                              //Patch response data
                              setPayment(response.monthly_payment);

                              localStorage.setItem('solicitud-total', values && values.total ? values.total : showCrediton ? 10000 : 50000)
                              localStorage.setItem('solicitud-cuotas', values && values.payment_freq ? values.payment_freq : 5)

                              setLoading(false);


                        } else {

                              setLoading(false);
                              setToast(true);

                              setTimeout(() => {
                                    setToast(false);
                              }, 5000);

                        }


                  })();

            }




      }


      useEffect(() => {
            setHasMounted(true);
            fetchApi();
      }, []);


      if (typeof window !== 'undefined') {

            //Set cbk
            let url = new URLSearchParams(window.document.location.search.substring(1));
            let cbk = url.get('cbk')

            if (cbk) {
                  let splitedCbk = cbk.split('-');

                  splitedCbk ? localStorage.setItem('cbk-total', splitedCbk[0]) : cbk = cbk;
                  splitedCbk ? localStorage.setItem('cbk-cuotas', splitedCbk[1]) : cbk = cbk;

            }
      }


      const getCuotas = () => {

            setLoading(true);

            setTimeout(() => {
                  document.getElementsByClassName('js-select-form')[0].blur()
            }, 150);


      }



      return (


            <>


                  {showToast && !loading &&
                        <div className="c-toast is-active" onClick={() => { setToast(false) }}>
                              <div className="c-toast_holder">
                                    <span className="c-toast__close" onClick={() => { setToast(false) }} />
                                    <span className="c-toast__alert"><strong>Error:</strong></span>
                                    <span className="c-toast__text">Ha ocurrido un error al enviar el formulario, intente nuevamente.</span>
                              </div>
                        </div>
                  }


                  <Formik

                        validateOnChange={false}
                        validateOnBlur={true}
                        validateOnMount={true}

                        initialValues={{
                              payment_freq: typeof window !== 'undefined' && localStorage.getItem('cbk-cuotas') != null ? localStorage.getItem('cbk-cuotas') : 5,
                              total:  typeof window !== 'undefined' && localStorage.getItem('cbk-total') != null ? localStorage.getItem('cbk-total') : showCrediton ? 10000 : 50000,
                        }}


                        onSubmit={
                              (values, actions) => {

                                    fetchApi(values);

                              }
                        }
                        validate={(values) => {

                              errors = {};

                              noValidate = true;

                              //Required
                              Object.keys(values).
                                    forEach((id) => {
                                          if (!values[id] || values[id] == "null") {
                                                errors[id] = campoError;
                                          }
                                    })


                              //Only number amount



                              //Cuotas
                              if (values.payment_freq && values.payment_freq < 1) {
                                    errors.payment_freq = "La cantidad de cuotas debe ser mayor a 1"
                              }

                              if (values.total && values.total < 4000) {
                                    errors.total = "El valor debe ser mayor a $4.000"
                              }

                              if (!showCrediton) {
                                    if (values.total && values.total < 50000) {
                                          setIsCrediton(true)
                                    } else {
                                          setIsCrediton(false)
                                    }
                              } else {
                                    if (values.total && values.total >= 50000) {
                                          setIsCreditonPlus(true)
                                    } else {
                                          setIsCreditonPlus(false)
                                    }
                              }

                              if (values.total && values.total > 150000) {
                                    errors.total = "El valor debe ser menor a $150.000"
                              }

                              //Round amount
                              if (values.total && values.total % 100 !== 0) {
                                    values.total = Math.ceil(values.total / 100) * 100;
                              }

                              //Send to api
                              if (Object.keys(errors).length === 0) {

                                    fetchApi(values)

                              } else {
                                    setPayment(null);
                              }


                              return errors;



                        }}
                  >
                        {({ values, handleChange, validateForm, setFieldValue, setValues }) => (


                              <Form key="cuotas-form" name="cuotas-form" method="POST" className={`${isMobile && 'c-hero__form'} c-form`}>

                                    <div className="c-form__group">

                                          <ErrorMessage name="total" children={() => { return (<label htmlFor="total" className="c-form__error">{errors.total}</label>) }} />

                                          <label className="c-form__label">
                                                <span className="c-form__label-text"> {!isMobile ? 'Monto a solicitar' : '¿Cuánto necesitás?'}</span>
                                                <span className="c-form__money">
                                                      <Field name="total" type="number" inputmode="decimal" className="c-form__input" min="4000" max="150000" step="100" placeholder="50000" onClick={e => e.target.select()}/>
                                                </span>
                                                
                                          </label>

                                    </div>
                                    <div className="c-form__group">

                                          <ErrorMessage name="payment_freq" children={() => { return (<label htmlFor="payment_freq" className="c-form__error">{errors.payment_freq}</label>) }} />

                                          <label className="c-form__label">
                                                <span className="c-form__label-text">{!isMobile ? 'Cantidad de cuotas' : '¿En cuántas cuotas?'}</span>

                                                <div className="c-form__select">

                                                      <Field name="payment_freq" as="select" onChange={(e) => { handleChange(e); getCuotas() }} className={`c-form__input js-select-form`}>


                                                            <option disabled value="null">Selecciona cantidad de cuotas...</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="9">9</option>
                                                            <option value="12">12</option>
                                                            <option value="15">15</option>
                                                            <option value="18">18</option>
                                                            <option value="24">24</option>
                                                            <option value="30">30</option>
                                                            <option value="36">36</option>
                                                      </Field>

                                                </div>

                                          </label>

                                    </div>


                                    <div className="c-form__submit">

                                          {((!isCrediton && !showCrediton) || (!isCreditonPlus && showCrediton)) &&
                                                <div className="c-form__submit-plus">

                                                      {payment && !loading && <div className="c-form__result">{values.payment_freq} cuotas de <NumberFormat value={payment} displayType={'text'} thousandSeparator={'.'} prefix={'$'} decimalSeparator={','} /></div>}

                                                      {loading && <div className="c-form__result"> <> <span className="c-loading-animation c-loading-animation--white" /><span className="c-loading-text">Cargando</span> </></div>}

                                                      <Link to={`/solicitar-prestamo`} disabled={!payment || !values.payment_freq || !values.total ? true : null} className={`c-btn c-form__btn c-send__btn js-send-form`}>¡Lo quiero ya!</Link>

                                                      <span className="c-form__financial-info c-text">*La cuota puede variar según el perﬁl crediticio del cliente. <br /> Ver más información sobre <a href="#" className="c-form__financial-link js-btn-financiera">tasas de interés aplicables</a>.</span>
                                                </div>
                                          }

                                          {!showCrediton && isCrediton &&
                                                <div className="c-form__submit-crediton">
                                                      <p className="c-form__crediton">Por préstamos menores a $50.000</p>
                                                      <a href={`https://www.crediton.com.uy/?cbk=${values.total}-${values.payment_freq}${localStorage.getItem('gclid') != null ? '&gclid=' + localStorage.getItem('gclid') : ''}`} target="_blank" className="c-btn c-form__btn c-send__btn js-send-form">Visitá<span className="c-btn__logo" /></a>
                                                </div>
                                          }

                                          {showCrediton && isCreditonPlus &&

                                                <div className="c-form__submit-plus">
                                                      <p className="c-form__plus">Por préstamos mayores a $50.000</p>
                                                      <a href={`https://www.creditonplus.com.uy/?cbk=${values.total}-${values.payment_freq}${localStorage.getItem('gclid') != null ? '&gclid=' + localStorage.getItem('gclid') : ''}`} target="_blank" className="c-btn c-form__btn c-form__btn--plus c-send__btn js-send-form">Visitá<span className="c-btn__logo" /></a>
                                                </div>


                                          }

                                    </div>



                              </Form>

                        )}

                  </Formik>


            </>
      )
}



CuotasForm.propTypes = {
      isMobile: PropTypes.bool
}


export default CuotasForm;
