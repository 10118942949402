import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { withPrefix, Link } from 'gatsby';
import Img from "gatsby-image";
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import RichText from '../components/richText';

import CuotasForm from "../components/cuotasForm";



const Home = (props) => {

  const showCrediton = true;
  const assets = 'assets'

  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };

  //Preguntas
  const $preguntasList = props.data.prismic.allPreguntas_frecuentess.edges[0].node;

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);


  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-home'} >


        <SEO metadata={$metadata}></SEO>

        <>


          <Helmet>

            <link href={withPrefix(`../../${assets}/plugins/tiny-slider/tiny-slider.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/tiny-slider-custom.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/icons.css?v=3`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css?v=3`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=10`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=4`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=4`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/home.css?v=8`)} rel="stylesheet" type="text/css" />



          </Helmet>

          {/* HERO */}

          {!showCrediton ?

            <div className="c-hero o-align-middle">
              <div className="c-hero__holder o-align-middle__aligner">
                <div className="o-wrapper">
                  <div className="js-header-waypoints" id="c-header-waypoint" />
                  <div className="c-hero__bill--left">
                    <StaticImage
                      backgroundColor="transparent"
                      layout="fullWidth"
                      placeholder="blurred"
                      objectFit="contain"
                      alt="Tu préstamo plus"
                      src={
                        "../../static/assets/images/hero-image-2@2.png"
                      }
                      formats={["webp", "png"]}
                    />
                  </div>
                  <div className="c-hero__bill--right">
                    <StaticImage
                      backgroundColor="transparent"
                      layout="fullWidth"
                      placeholder="blurred"
                      objectFit="contain"
                      alt="Tu préstamo plus"
                      src={
                        "../../static/assets/images/hero-image-1@2.png"
                      }
                      formats={["webp", "png"]}
                    />
                  </div>
                  <h2 className="c-title c-title--big c-title--white c-hero__title">Tu préstamo plus <br />al <strong className="c-title--outline c-title--outline-white">toque</strong></h2>

                  <Link to={`/solicitar-prestamo`} className="c-btn c-hero__btn">¡Lo quiero ya!</Link>

                </div>
              </div>{/* .c-hero__holder */}
            </div>

            :

            <div className="c-hero o-align-middle">{/*
                      */}<div className="c-hero__wrapper o-align-middle__aligner">
                <div className="o-wrapper">
                  <div className="c-hero__holder">
                    <div className="js-header-waypoints" id="c-header-waypoint" />
                    <h2 className="c-hero__title c-title c-title--white c-title--center">¡Tu préstamo <br />en <strong className="c-title--outline c-title--outline-white">instantes!</strong></h2>

                    <Link class="c-hero__btn c-btn" to="/solicitar-prestamo">¡Lo quiero ya!</Link>

                    <CuotasForm isMobile={true} />

                    <div className="c-hero__image-mobile">

                      <StaticImage
                        backgroundColor="transparent"
                        layout="fullWidth"
                        placeholder="blurred"
                        objectFit="contain"
                        alt="Tu préstamo"
                        src={
                          "../../static/assets/images/hero-image-2@2.png"
                        }
                        formats={["webp", "png"]}
                      />


                    </div>

                  </div>{/* .c-hero__holder */}
                </div>
              </div>
              <div className="c-hero__image">

                <StaticImage
                  backgroundColor="transparent"
                  layout="fullWidth"
                  placeholder="blurred"
                  objectFit="contain"
                  alt="Tu préstamo"
                  src={
                    "../../static/assets/images/hero-image-2@2.png"
                  }
                  formats={["webp", "png"]}
                />


              </div>
            </div>

          }



          {/* SOLICITUD */}

          <div className="c-home-solicitud o-wrapper o-section">


            {hasMounted &&
              <div className="c-home-solicitud__holder">

                {showCrediton && <h2 className="c-title c-title--small c-title--red">Solicitá hasta <strong className="c-form__title-amount">$50.000</strong></h2>}
                {!showCrediton && <h2 className="c-title c-title--small c-title--blue">Solicitá hasta <strong className="c-form__title-amount">$ 150.000</strong></h2>}


                <CuotasForm isMobile={false} />


              </div>
            }
          </div>


          {hasMounted &&

            <Helmet>

              <script src={`/${assets}/plugins/tiny-slider/tiny-slider.js`}></script>
              <script src={`/${assets}/js/sitio/home.js`}></script>

            </Helmet>

          }


          {/* SERVICE */}
          {showCrediton && hasMounted &&
            <div className="c-home-service o-section js-services-carousel js-carousel__notInit">
              <h2 className="c-title c-title--small  c-title--red">Pedir tu crédito <br />nunca fue tan fácil</h2>
              <div className="c-carousel__viewport">
                <ol className="c-home-service__list js-carousel">
                  <li className="c-home-service__item">
                    <span className="c-home-service__item-text c-text">
                      Elegí el monto que buscás,<br /> la cantidad de cuotas y completá tus datos.
              </span>
                  </li>
                  <li className="c-home-service__item js-carousel__notInit--notInitHide">
                    <span className="c-home-service__item-text c-text">
                      En minutos recibí la aprobación por WhatsApp.
              </span>
                  </li>
                  <li className="c-home-service__item js-carousel__notInit--notInitHide">
                    <span className="c-home-service__item-text c-text">
                      Retirá tu préstamo por cualquier<span className="u-hidden-for-seo">Redpagos</span><span className="c-home-service__icon" />del país.
              </span>
                  </li>
                </ol>
              </div>
              <Link className="c-home-service__btn c-btn c-btn--outline" to="/solicitar-prestamo">Solicitar préstamo</Link>
            </div>
          }

          {showCrediton &&
            <div className="c-home-requirements o-section" id="comunicado">
              <div className="o-wrapper o-wrapper--xs">
                
              <div className="c-home-comunicado__holder">
                <div className="c-home-requirements__holder">
                    <h2 className="c-home-requirements__title c-title c-title--small c-title--white">Comunicado</h2>
                    
                      <p className="c-home-comunicado__text c-text">La Asociación de Bancos Privados del Uruguay (ABPU) y la Asociación Nacional de Empresas Administradoras de Crédito (ANEC), con el apoyo del Ministerio de Economía y Finanzas (MEF) y la articulación del Banco Central del Uruguay (BCU), ponen en marcha el Programa Voluntario de Reestructuración de Deuda, para dar una solución a la situación crediticia de uruguayos que luego de la pandemia, han tenido dificultades para afrontar sus deudas. El Programa implica – para todas las deudas en categoría 5 según la Central de Riesgos del BCU a Abril de 2022  – la remisión de todas las deudas menores a $5.000 de capital y la reestructuración de deudas entre $5.000 y $100.000 de capital a <strong>tasa cero</strong>. CreditON adhiere al Programa en todos sus términos en búsqueda de facilitar una solución para sus clientes afectados. Por más información comunicate con CreditON o visita este <strong><a href="https://soluciondeuda.com.uy/landing" target="_blank">link.</a></strong></p>
                    
                  </div >
                  <div className="c-home-comunicado__img">

                    <StaticImage
                        backgroundColor="transparent"
                        layout="fullWidth"
                        placeholder="blurred"
                        objectFit="contain"
                        alt="Tu préstamo plus"
                        src={"../../static/assets/images/abpu_logo.png"}
                        formats={["webp", "png"]}
                      />

                  </div>
                </div>
              </div>
            </div>
          }

          {/* CALL */}
          <div className="c-home-call">
            <div className={`c-home-call__holder o-wrapper ${showCrediton && 'o-wrapper--xs'} `}>

              {showCrediton ?
                <div className="c-home-call__img">
                  <StaticImage
                    backgroundColor="transparent"
                    layout="fullWidth"
                    placeholder="blurred"
                    objectFit="contain"
                    alt="Tu préstamo plus"
                    src={"../../static/assets/images/home-call-image@2.png"}
                    formats={["webp", "png"]}
                  />
                </div> :
                <div className="c-home-call__img">
                  <StaticImage
                    backgroundColor="transparent"
                    layout="fullWidth"
                    placeholder="blurred"
                    objectFit="contain"
                    alt="Tu préstamo plus"
                    src={"../../static/assets/images/call-image@2.png"}
                    formats={["webp", "png"]}
                  />
                </div>
              }
              {/*
                */}<div className="c-home-call__info o-section">
                {!showCrediton && <h2 className="c-title c-title--white">un whatsapp,<br /> un <strong className="c-title--outline c-title--outline-white">préstamo</strong></h2>}
                {showCrediton && <h2 className="c-title c-title--white">Tu préstamo<br /> a un WhatsApp </h2>}
                <a href="#" className="c-title c-title--white c-home-call__wsp js-whatsapp-btn" rel="noreferrer">{showCrediton ? '091 200 055' : '099 270 679'}</a>
                <a href="#" className="c-btn c-home-call__btn js-whatsapp-btn" rel="noreferrer"><span className="c-btn--icon icon-wsp"></span> ¡Whatsappeanos!</a>
              </div>{/* .c-home-call__info */}
            </div>{/* .c-home-call__img */}
          </div>{/* .c-home-call */}



          {/* SERVICE */}
          {!showCrediton && hasMounted &&
            <div className="c-home-service o-section js-services-carousel js-carousel__notInit">
              <h2 className="c-title c-title--small  c-title--blue">¿Cómo funciona?</h2>
              <div className="c-carousel__viewport">
                <ul className="c-home-service__list js-carousel">
                  <li className="c-home-service__item">
                    <span className="c-home-service__icon icon-how-it-works-1" />
                    <span className="c-home-service__item-text c-text">
                      <strong className="c-home-service__title">Rápido</strong>
                      Elegí el monto que buscás, la cantidad de cuotas y dejanos tus datos.
                </span>
                  </li>
                  <li className="c-home-service__item js-carousel--notInitHide">
                    <span className="c-home-service__icon icon-how-it-works-2" />
                    <span className="c-home-service__item-text c-text">
                      <strong className="c-home-service__title">Muy Fácil</strong>
                      En minutos recibí la aprobación por WhatsApp.
                </span>
                  </li>
                  <li className="c-home-service__item js-carousel--notInitHide">
                    <span className="c-home-service__icon icon-how-it-works-3" />
                    <span className="c-home-service__item-text c-text">
                      <strong className="c-home-service__title">Accesible</strong>
                      Retirá tu crédito por cualquier RedPagos del país.
                </span>
                  </li>
                </ul>
              </div>
            </div>
          }

          {/* PAYMENT */}
          {showCrediton &&
            <div className="c-home-payment o-section">
              <h2 className="c-home-payment__title c-title c-title--small c-title--red">¡Pagá tus cuotas desde dónde estés!</h2>
              <span className="c-home-payment__method c-home-payment__method--itau" />
              <span className="c-home-payment__method c-home-payment__method--paganza" />
              <span className="c-home-payment__method c-home-payment__method--redpagos" />
              <Link className="c-home-payment__btn c-btn c-btn--outline" to="/medios-de-pago">Ver más</Link>
            </div>
          }


          {/* DOCUMENTATION */}
          {!showCrediton &&
            <div className="c-home-documentation" id="requirements">
              <div className="c-home-documentation__color">
                <div className="c-home-documentation__holder o-wrapper">

                  <div className="c-home-documentation__img">
                    <StaticImage
                      backgroundColor="transparent"
                      layout="fullWidth"
                      placeholder="blurred"
                      objectFit="contain"
                      alt="Tu préstamo plus"
                      src={"../../static/assets/images/documentation-image@2.png"}
                      formats={["webp", "png"]}
                    />

                  </div>{/*
                    */}<div className="c-home-documentation__info o-section">
                    <h2 className="c-home-documentation__title c-title c-title--outline c-title--outline-white c-title--white">Más documentación <br /><strong className="c-home-documentation__title-strong c-title--boxshadow">¡mejores condiciones!</strong></h2>
                    <ul className="c-home-documentation__list">
                      <li className="c-home-documentation__item c-text">Tener entre 25 y 80 años. </li>
                      <li className="c-home-documentation__item c-text">Cédula de Identidad uruguaya vigente.</li>
                      <li className="c-home-documentation__item c-text">No estar en el clearing y tener un buen historial crediticio.</li>
                      <li className="c-home-documentation__item c-text">Ingresos líquidos demostrables mayores a $40.000.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          }



          {/* REQUIREMENTS */}
          {showCrediton &&
            <div className="c-home-requirements o-section" id="requirements">
              <div className="o-wrapper o-wrapper--xs">
                <div className="c-home-requirements__holder">
                  <h2 className="c-home-requirements__title c-title c-title--small c-title--white">Requisitos</h2>
                  <ul className="c-home-requirements__list">
                    <li className="c-home-requirements__item c-home-requirements__item--age c-text">Tener entre 21 y 80 años.</li>
                    <li className="c-home-requirements__item c-home-requirements__item--ci c-text">C.I. Uruguaya vigente.</li>
                    <li className="c-home-requirements__item c-home-requirements__item--clearing c-text">No estar en el clearing.</li>
                  </ul>
                  <Link className="c-home-requirements__btn c-btn" to="/solicitar-prestamo">solicitar préstamo</Link>
                </div>
                <div className="c-home-requirements__img" />
              </div>
            </div>
          }


          {hasMounted &&

            <Helmet>
              <script src={`../../${assets}/js/sitio/faqs.js`} />
            </Helmet>

          }

          {/* FAQs */}
          <div className="c-faqs o-section">
            <div className={`c-faqs__holder o-wrapper`}>
              <h2 className={`c-faqs__title c-title c-title--white ${showCrediton && 'c-title--red c-title--small'}`}>preguntas frecuentes</h2>
              <ul>

                {$preguntasList.item.map((field, i) => (

                  i < 4 &&
                  <li key={i} className={`c-faqs__item `} >
                    <h3 className="c-faqs__question"><span className="c-faqs__question-aligner">{field.pregunta[0].text}</span></h3>
                    <div className="c-faqs__answer c-text">

                      <RichText render={field.respuesta} />

                    </div>
                  </li>

                ))}


              </ul>

              <Link to={`/preguntas-frecuentes`} className={`c-btn c-faqs__btn ${showCrediton ? 'c-btn--outline' : 'c-hero__btn'}`}>Ver todas</Link>

            </div>
          </div>



        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query HomeQuery  {
  prismic {
    allMetadatas  {
      edges {
        node {
          ...MetadataFragment
        }
      }
    },
    allFooters {
      edges {
        node {
          ...FooterFragment
        }
      }
    },
    allPreguntas_frecuentess (first:4) {
      edges {
        node {
          item {
            pregunta
            respuesta
          }
        }
      }
    },
    allInformacion_financieras {
      edges {
        node {
          ...InformacionFinancieraFragment
        }
      }
    }
  }
}
`


export default Home;

